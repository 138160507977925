var $ = require('../internals/export');
var DESCRIPTORS = require('../internals/descriptors');
var defineProperties = require('../internals/object-define-properties').f;

// `Object.defineProperties` method
// https://tc39.es/ecma262/#sec-object.defineproperties
// eslint-disable-next-line es/no-object-defineproperties -- safe
$({
  target: 'Object',
  stat: true,
  forced: Object.defineProperties !== defineProperties,
  sham: !DESCRIPTORS
}, {
  defineProperties: defineProperties
});